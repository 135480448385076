<template>
    <section>
        <div class="row mx-0 pt-5">
            <div class="col-auto" />
            <div class="col px-0">
                <ValidationObserver ref="validacion" v-slot="{ valid }">
                    <div class="row mx-0">
                        <div class="col-auto" />
                        <div class="col-5">
                            <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:40" name="motivo">
                                <p class="text-general f-14 pl-3">Nombre de motivos</p>
                                <el-input v-model="model.nombre" size="small" class="w-100" show-word-limit maxlength="40">
                                    <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                </el-input>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto pt-3 px-2">
                            <div class="bg-general mt-1 text-white br-8 d-middle-center" style="width:32px;height:32px;">
                                <i class="icon-plus f-20 cr-pointer" @click="CrearRegistro(valid)" />
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
                <div class="row mx-0 my-3" />
                <div class="row mx-0 mb-2">
                    <div class="col-auto" />
                    <p class="col f-15 text-general">
                        Lista de motivos
                    </p>
                </div>
                <ValidationObserver v-if="motivos.length" ref="validacion2" v-slot="{ valid }">
                    <draggable :list="motivos" handle=".handle" @change="actualizar_posicion">
                        <div v-for="(motivo, idx) in motivos" :key="idx" class="row mx-0 my-2">
                            <i class="icon-drag f-22 cr-pointer handle text-muted" />
                            <div class="col-auto" />
                            <div class="col-5">
                                <ValidationProvider v-slot="{errors}" :vid="'nombre'+idx" rules="required|max:40" name="motivo">
                                    <el-input v-model="motivo.nombre" class="w-100" size="small" :disabled="editar != idx" maxlength="40" show-word-limit>
                                        <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    </el-input>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-2">
                                <div v-if="editar != idx" class="btn-action border" @click="editar = idx">
                                    <i class="icon-pencil-outline text-general2 cr-pointer f-20" />
                                </div>
                                <div v-else class="btn-action" @click="editarTendero(valid, motivo)">
                                    <i class="icon-ok-circled-outline text-success cr-pointer f-20" />
                                </div>
                            </div>
                            <div class="col-1 px-2">
                                <el-tooltip
                                v-if="editar != idx"
                                class="item "
                                effect="light"
                                placement="bottom" 
                                >
                                    <div slot="content" class="text-center" style="max-width:230px;">
                                        {{ motivo.productos_stocks_retiros_count > 0 ? 'No puede eliminar este motivo por que está siendo usado' : 'Eliminar' }}
                                    </div>
                                    <div
                                    :class="`${motivo.productos_stocks_retiros_count > 0 ? 'text-gris':'hover-inverse'} cr-pointer br-10`"
                                    class="btn-action border"
                                    @click="motivo.productos_stocks_retiros_count > 0 ? '':eliminar(motivo.id)"
                                    >
                                        <i class="icon-delete-outline text-general2 cr-pointer f-20" />
                                    </div>
                                </el-tooltip>
                                <!-- <i v-if="editar != idx" class="icon-delete-outline text-general2 cr-pointer f-20" @click="eliminar(motivo.id)" /> -->
                            </div>
                            <div class="col-auto">
                                <el-tooltip placement="bottom" content="Unidades de productos retirados por este motivo" effect="light">
                                    <div class="bg-general3 br-20 px-3 text-white d-middle">
                                        <i class="icon-package-variant-closed f-20 mr-2" />
                                        <span>{{ motivo.productos_stocks_retiros_count }}</span>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </draggable>
                </ValidationObserver>
                <sinDatos v-else icon="list" mensaje="No se ha creado ningún motivo" />
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar motivo" mensaje="¿Desea eliminar el motivo?" @eliminar="EliminarRegistro" />
    </section>
</template>

<script>
import CancelacionAdmin from '~/services/configurar/admin/cancelacionAdmin'
import motivosRetiros from '~/services/configurar/admin/motivosRetiros'

export default {
    data(){
        return{
            editar: -1,
            model:{
                nombre:null,
                tipo:42
            },
            id_eliminar:null,
            motivos:[]
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async listar(){
            try {
                const {data} = await motivosRetiros.listarTrasladoCedis()
                this.motivos = data.map(el => ({...el, estado: !!el.estado}))
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearRegistro(valid){
            try {
                if(valid){
                    const {data} = await CancelacionAdmin.PostCrear(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.listar()
                    this.$refs.validacion.reset();
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarTendero(valid,row){
            try {
                if(valid){
                    this.editar =- 1
                    let datos={
                        id:row.id,
                        nombre:row.nombre,
                        tipo:row.tipo,
                    }
                    const {data} = await CancelacionAdmin.PutEditar(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listar()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async EliminarRegistro(){
            try {
                const {data} = await CancelacionAdmin.Delete(this.id_eliminar, this.model.tipo)
                this.notificacion('', data.mensaje, 'success')
                this.listar()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async CambiodeEstado(row){
            try {
                let datos={
                    id:row.id,
                    estado:!row.estado
                }
                const {data} = await CancelacionAdmin.PutEstado(datos)
                this.notificacion('', data.mensaje, 'success')
                this.listar()
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizar_posicion(event){
            const datos = {
                antiguaPosicion: event.moved.oldIndex + 1,
                nuevaPosicion: event.moved.newIndex + 1,
                tipo: event.moved.element.tipo,
                id: event.moved.element.id,
            }
            const { data } = await CancelacionAdmin.PostActualizarPosicion(datos)
            this.notificacion('', data.mensaje, 'success')
            this.listar()
        },
        limpiar(){
            this.model.nombre = null
        }
    }
}
</script>